<template>
    <div id="dashboard-content">
        <h4 class="mb-3">Hasil Turnitin</h4>
        <div v-if="!pdfsrc && notFound" class="border shadow-sm d-flex align-items-center justify-content-center" style="height: 600px;">Hasil Turnitin Tidak Ditemukan</div>
        <div v-else-if="!pdfsrc && !notFound" class="border shadow-sm d-flex align-items-center justify-content-center flex-column" style="height: 600px;">
            <div class="spinner-border mb-2" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span>Memuat PDF Hasil Turnitin</span>
        </div>
        <pdf v-else-if="!showAllPage" :src="pdfsrc" ></pdf>
        <iframe v-else :src="pdfsrc" class="w-100" height="1000px" frameborder="0"></iframe>
    </div>
</template>
  
<script>
import { mapActions } from 'vuex'
import pdf from "vue-pdf"

export default {
  name: "ResultTurnitin",
  data() {
      return {
          book_id: this.$route.params.book_id,
          pdfsrc: null,
          notFound: false,
          showAllPage: false,
      }
  },
  components: {pdf},
  methods: {
    ...mapActions(['fetchResultTurnitin', 'fetchBookSummary']),
  },
  created() {
    // Fetch summary for check status recommended
    this.fetchBookSummary(this.book_id).then(response => {
      if(response.data.recommended == "1") this.showAllPage = true;
    })
    // Fetch result turnitin
    this.fetchResultTurnitin(this.book_id)
      .then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        const objectUrl = URL.createObjectURL(blob);
        this.pdfsrc = objectUrl;
      })
      .catch(error => {
        console.log(error)
        this.notFound = true;
      })
      .finally(() => {
        // Prevent Memory Leak
        setTimeout(() => {
          if (this.pdfsrc) {
            URL.revokeObjectURL(this.pdfsrc);
            console.log('URL revoked');
          }
        }, 5000);
      });
  },
  beforeRouteEnter(_, __, next) {
    const { role_name } = JSON.parse(localStorage.getItem("user"));
    if (role_name === "Penerbit") next();
    else next("/user/home");
  },
}
</script>